import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Indoor_Cameras/IN-6014_HD/NavButtons';
import CompareIndoor from 'components/Products/Compare/CompareIndoorFlyout';
import TableFeatures from 'components/Indoor_Cameras/IN-6014_HD/TableFeatures';
import IndoorData from 'components/Products/Flyouts/Graphs/IndoorGraphsFlyout';
import RadarGraphCard from 'components/Products/RadarGraphs/in6014-radargraph-grid';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR HD Kamera Hardware Reset",
  "path": "/Indoor_Cameras/IN-8001_HD/Camera_Reset/",
  "dateChanged": "2018-01-22",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IN-8001 Full HD Indoor IP Kamera Werksreset",
  "image": "./P_SearchThumb_IN-8001HD_Reset.png",
  "social": "/images/Search/P_SearchThumb_IN-8001HD_Reset.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-8001HD_white.webp",
  "chapter": "Indoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='IN-8001 Full HD Hardware Reset' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IN-8001 HD is the first INSTAR camera with the new 1080p chipset.' image='/images/Search/P_SearchThumb_IN-8001HD_Reset.png' twitter='/images/Search/P_SearchThumb_IN-8001HD_Reset.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Innenkameras/IN-8001_HD/Kamera_Reset/' locationFR='/fr/Indoor_Cameras/IN-8001_HD/Camera_Reset/' crumbLabel="Reset" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "in-6014-hd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#in-6014-hd",
        "aria-label": "in 6014 hd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-6014 HD`}</h1>
    <CompareIndoor mdxType="CompareIndoor" />
    <h2 {...{
      "id": "product-overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#product-overview",
        "aria-label": "product overview permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Product Overview`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/eb94c141317150960cd72af3527e3ffe/573d3/IN-6014_Overview_width1650.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACCElEQVQoz03SzW/ScBgH8P4D8+wuLnE3uM1wICbAihjvcAEyYS94AQIRktkmYJSOkVpHNGMZ1BcaOZV5IQ6DZ6MhPVaGzMsg6YEQDcoKbWn7GKiSfa+/fJ7n9yRfRNV0RdUB4POXr8R+LkMQqVSq3+8DgKZpqqrCPLqu93q9s9YZx3Gd83NFUQAAEWVF1OCXKDlc91DX3VDowdK1pePj4gJrmgYAHMdZLBafz5dIJmx2W6VS+YdVAL7zw+68c9tmi0ajN1ZW8vm8KIoAsMAMw1xfXl5bu7W+jprMJoIgZFlG1Pnb907n+UF+99FuPB4jSZJl2eFweBVXq9VAMGgym2+urm5ubtF0aTKZILo+O7jdbuM4JgjCRfei2+1iOD4ajQxsnE2SZDabFQSB5/lGo4Fh2OzbxuBvrZbf72cYptlslkqlSCQiSdJVTFGU1Wot0XS9Xg+FQuFweIaNzblcDkXRdDpNUVQymXS5XOVyGQBkWQYAnue9Xq/X5w0ENna2t+4HAg6Ho/GpgQDAYDCw2+1ut5skyUKhgOM4iqLGbGM/sbfndDr3s0/y5OO39OGr1288Hk8sFpthRVEoinqayRSLxdPTDyfvTzAMq3+sL3CtVgtu7xweHT2jqIMXL+nyu/jDBMuyCPyPLMvjeSRJmk6nRjEWJRlL8mgsXY6l35eTn3/EiTwryV8rOq76kqdbIgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/eb94c141317150960cd72af3527e3ffe/e4706/IN-6014_Overview_width1650.avif 230w", "/en/static/eb94c141317150960cd72af3527e3ffe/d1af7/IN-6014_Overview_width1650.avif 460w", "/en/static/eb94c141317150960cd72af3527e3ffe/7f308/IN-6014_Overview_width1650.avif 920w", "/en/static/eb94c141317150960cd72af3527e3ffe/e1c99/IN-6014_Overview_width1650.avif 1380w", "/en/static/eb94c141317150960cd72af3527e3ffe/e02f7/IN-6014_Overview_width1650.avif 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/eb94c141317150960cd72af3527e3ffe/a0b58/IN-6014_Overview_width1650.webp 230w", "/en/static/eb94c141317150960cd72af3527e3ffe/bc10c/IN-6014_Overview_width1650.webp 460w", "/en/static/eb94c141317150960cd72af3527e3ffe/966d8/IN-6014_Overview_width1650.webp 920w", "/en/static/eb94c141317150960cd72af3527e3ffe/445df/IN-6014_Overview_width1650.webp 1380w", "/en/static/eb94c141317150960cd72af3527e3ffe/44758/IN-6014_Overview_width1650.webp 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/eb94c141317150960cd72af3527e3ffe/81c8e/IN-6014_Overview_width1650.png 230w", "/en/static/eb94c141317150960cd72af3527e3ffe/08a84/IN-6014_Overview_width1650.png 460w", "/en/static/eb94c141317150960cd72af3527e3ffe/c0255/IN-6014_Overview_width1650.png 920w", "/en/static/eb94c141317150960cd72af3527e3ffe/b1001/IN-6014_Overview_width1650.png 1380w", "/en/static/eb94c141317150960cd72af3527e3ffe/573d3/IN-6014_Overview_width1650.png 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/eb94c141317150960cd72af3527e3ffe/c0255/IN-6014_Overview_width1650.png",
              "alt": "INSTAR IN-6014 HD",
              "title": "INSTAR IN-6014 HD",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <TableFeatures mdxType="TableFeatures" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The IN-6014 HD is an all-round solution for 720p indoor surveillance. The camera head is remotely controllable and equipped with infrared diodes to utilize night vision in pitch dark environments. The integrated IR cut filter on the other hand allows for the capturing of native colours, as seen by the human eye. Through the use of an efficient h.264 compression codec it is able of recording video files on `}<Link to="/Motion_Detection/SD_Card_Access/" mdxType="Link">{`SD Memory Card`}</Link>{` with comparably small size but best picture results. Any device (i.e. smartphones) can connect to the cameras web interface and allows you to control the pan & tilt feature. With 350 degrees horizontally and 100 degrees vertically range of movement. Thanks to its compact size and its modern design, you won't be just limited to use it as a surveillance camera but also for your personal projects. The `}<Link to="/Motion_Detection/Alarm_FTP_Upload/" mdxType="Link">{`FTP Support`}</Link>{` allows you to use the IN-6014 HD as a multi-functional webcam for `}<Link to="/Advanced_User/Website_Integration/" mdxType="Link">{`Integration on your Website`}</Link>{`.`}</p>
    <IndoorData mdxType="IndoorData" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "hardware",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hardware",
        "aria-label": "hardware permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hardware`}</h3>
    <ul>
      <li parentName="ul">{`Integrated PIR heat detector for advanced motion detection`}</li>
      <li parentName="ul">{`Remote controlled Pan & Tilt - 350° pan and 100° tilt`}</li>
      <li parentName="ul">{`2-Way audio via an integrated `}<Link to="/Web_User_Interface/1080p_Series/Multimedia/Audio/" mdxType="Link">{`Microphone & Speaker`}</Link></li>
      <li parentName="ul">{`Wide Dynamic Range (WDR) 1080p `}<Link to="/Indoor_Cameras/IN-6014_HD/Technical_Specifications/" mdxType="Link">{`Panasonic CMOS Sensor`}</Link></li>
    </ul>
    <h3 {...{
      "id": "network",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#network",
        "aria-label": "network permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network`}</h3>
    <ul>
      <li parentName="ul">{`RJ-45 Ethernet port for an easy integration into your 100Mbit home network`}</li>
      <li parentName="ul">{`Compatible with 2.4 GHz `}<Link to="/Quick_Installation/Set_Up_A_Wireless_Connection/" mdxType="Link">{`2.4 GHz WiFi`}</Link>{` IEEE 802.11 b/g/n up to 54Mbit networks with WEP, WPA and WPA2 security`}</li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/720p_Series/Network/IP_Config/" mdxType="Link">DHCP</Link> and <Link to="/Web_User_Interface/720p_Series/Network/UPnP/" mdxType="Link">UPnP</Link> support for an automatic network integration
      </li>
      <li parentName="ul">{`Integrated personal, free DDNS ("Internet") Address and P2P User ID to access your camera through the Internet`}</li>
    </ul>
    <h3 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h3>
    <ul>
      <li parentName="ul">{`Free `}<Link to="/Software/Android/" mdxType="Link">{`Android`}</Link>{`, `}<Link to="/Software/iOS/" mdxType="Link">{`iPhone`}</Link>{` and `}<Link to="/Software/Windows/" mdxType="Link">{`Windows Phone`}</Link>{` App`}</li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/720p_Series/" mdxType="Link">Web user interface</Link> allows an easy configuration and camera control through your default web browser (Chrome, Safari, Firefox, Internet Explorer)
      </li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/720p_Series/System/User/" mdxType="Link">Multi user management</Link> and simultaneous login for 3 users
      </li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/720p_Series/Alarm/Areas/" mdxType="Link">Software-based motion</Link> and <Link to="/Web_User_Interface/720p_Series/Alarm/Actions/" mdxType="Link">audio</Link> detection and <Link to="/Motion_Detection/Alarm_Notification/" mdxType="Link"> email notification</Link> with attached snapshots
      </li>
      <li parentName="ul">{`Record videos directly to an `}<Link to="/Motion_Detection/SD_Card_Access/" mdxType="Link">{`Internal SD Card`}</Link></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    
    <p>{`The 1/3 inch WDR sensor is a `}<strong>{`Panasonic CMOS`}</strong>{` chip, enabling 1080p HD video at unparalleled low-light sensitivity. Panasonic offers a wide variety of image sensors (`}<OutboundLink href="http://www.semicon.panasonic.co.jp/en/products/imagesensors/surveillance/" mdxType="OutboundLink">{`νMaicovicon®`}</OutboundLink>{`) for security and network cameras, which are based on the industry's most advanced technology and can be applied to security cameras requiring high picture quality with high sensitivity and low noise. The CMOS image sensor, νMaicovicon®, features high picture quality equal to high-speed CCD technologies, high S/N ratio, low noise, and high dynamic range. Even in a dark condition, you can retrieve snapshots with excellent character detection, color reproduction, as well as object detection. Even in scenes with fast-moving objects, the image sensor ensures clear images without motion blur or strain. The frame composite function (WDR) enables high-dynamic range - you can simultaneously resolve dark and bright areas in your video frame.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><em parentName="p">{`** The Model IN-6012 HD only offers half the resolution, compared to the IN-6014 HD, but - with its reduced angle of view - still offers a very detailed image. The camera model IN-6014 HD uses a similar lense as the IN-6014 HD, with a slightly a bigger field of view because of its slightly bigger sensor and wider lense.`}</em></p>
    <RadarGraphCard mdxType="RadarGraphCard" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The IN-6014 HD is the first INSTAR HD 720p pan & tilt camera with an integrated infrared motion detector. The camera can easily be controlled through your smartphone from inside your network and also over the internet. The camera can easily be connected to your network by using its 2.4GHz (802.11b/g/n) Wi-Fi module (WPA/WPA2/CCMP) or through the 100MBit network interface to ensure a fluid video stream. The camera can also see during the night thanks to 10 Infrared LEDs operating at a wavelength of 850nm. The max. indoor range for the cameras night vision is around 5-10 meters. Our new fullHD indoor camera will bring you all the features that you are used to from our 720p series, combined with a superior resolution of 1280x720 Pixel and the possibility to record videos on an integrated SD card. The IN-6014 HD is the camera that combines the powerful software based motion detection with a hardware heat motion detector (PIR). Both methods can be used or bundled to increase their effectiveness by minimizing their false alarm rate.`}</p>
    <p>{`The 1/3 inch WDR sensor is built on a 4.3 micron OmniPixel3-HS™ Pixel enabling 720p HD at unparalleled low-light sensitivity and a dynamic range in colour of 115 dB – compared to the 60 db of a regular VGA IP Camera. WDR allows you to capture high detailed colour videos even in high-contrast environmental lighting conditions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      